<template>
  <div class="wrapper">
    <div class="border-top"></div>
    <div class="introduction">
      <div class="introduction-title">引言</div>
      <div class="introduction-desc" v-html="classInfo.resources_class_introduction"></div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item,index) in resourcesList"
        :key="index"
        @click="selectItem(item)"
      >
        <div class="item-cover">
          <img class="item-cover-img" :src="item.resources_cover"/>
        </div>
        <div class="item-info">
          <div class="info-title">{{ item.resources_name }}</div>
          <div class="info-desc">{{ item.resources_desc }}</div>
          <div class="info-author">
            <div class="author-icon"></div>
            <div class="author-name">{{ item.resources_author }}</div>
            <div class="label">{{ item.resources_type }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAudios } from '@/api/Resources'
import { Toast } from 'vant'
import { appInit } from '@/utils/init'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BooksDetailList',
  props: {
    classInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    resourcesList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters([
      'userGuid'
    ])
  },
  methods: {
    selectItem (item) {
      if (item.resources_type_int === 1 || item.resources_type_int === 5) {
        this.$router.push(this.$route.path + '/book-detail/' + item.resources_guid + '/' + item.resources_name)
      } else if (item.resources_type_int === 2 || item.resources_type_int === 6) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        getAudios({
          resources_guid: item.resources_guid,
          c_user_guid: this.userGuid
        }).then(res => {
          if (res.code === 200) {
            if (!res.data.list.length) {
              Toast.fail('暂无音频数据')
              return false
            }
            this.selectPlay({
              list: res.data.list,
              index: res.data.current_index
            })
          } else if (res.code === 401) {
            appInit(this.agencyGuid, this.$route.path)
          } else if (res.code === 402) {
            this.$router.push({
              name: 'Login',
              params: {
                link: this.$route.path
              }
            })
          } else {
            Toast.fail(res.msg)
          }
          Toast.clear()
        })
      } else if (item.resources_type_int === 3) {
        this.$router.push(this.$route.path + '/video-detail/' + item.resources_guid + '/' + item.resources_name)
      }
    },
    ...mapActions([
      'selectPlay'
    ])
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display table
  width 100%
  margin-top 20px

  .border-top
    width 100%
    height 45px
    bg-image('~@/assets/img/books-detail/border-top')
    background-size 100% 100%
    background-repeat no-repeat

  .introduction
    display table
    width 100%
    margin-top -2px
    padding-top 20px
    padding-bottom 25px
    background rgba(255, 255, 255, 1)

    .introduction-title
      width 720px
      height 30px
      margin-top 20px
      padding-left 22px
      border-left 8px solid rgba(179, 53, 58, 1)

    .introduction-desc
      width 690px
      font-size 30px
      line-height 48px
      color rgba(51, 51, 51, 1)
      padding 30px 0
      margin 0 auto
      border-bottom 30px solid rgba(245, 245, 244, 1)

  .list
    width 100%
    background rgba(255, 255, 255, 1)

    .item
      display flex
      align-items center
      width 690px
      height 228px
      padding 25px 30px

      .item-cover
        width 166px
        height 228px
        border-radius 10px
        overflow hidden

        .item-cover-img
          width 100%
          height 100%

      .item-info
        display table
        width 480px
        height 228px
        padding-left 25px

        .info-title
          width 480
          height 44px
          line-height 54px
          font-size 26px
          color rgba(51, 51, 51, 1)

        .info-desc
          width 480px
          height 68px
          margin-top 46px
          font-size 24px
          line-height 34px
          color rgba(153, 153, 153, 1)
          no-wrap2(2)

        .info-author
          display flex
          align-items center
          width 480px
          height 38px
          margin-top 22px
          overflow hidden

          .author-icon
            width 15px
            height 16px
            bg-image('~@/assets/img/books-detail/author')
            background-size 100% 100%
            background-repeat no-repeat

          .author-name
            width 320px
            padding-left 10px
            font-size 22px
            color rgba(199, 199, 199, 1)
            line-height normal
            no-wrap()

          .label
            height 32px
            padding 0 10px
            line-height 36px
            font-size 20px
            color rgba(245, 151, 1, 1)
            border 1px solid rgba(245, 151, 1, 1)
            border-radius 6px
</style>
