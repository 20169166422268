<template>
  <div class="wrapper">
    <div class="cover">
      <img class="cover-img" v-if="classInfo.resources_class_advert" :src="classInfo.resources_class_advert" alt=""/>
    </div>
<!--    <div class="title">-->
<!--      {{ classInfo.resources_class_name }}-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'BooksDetailInfo',
  props: {
    classInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  flex-wrap wrap
  justify-content center
  width 750px
  background rgba(245, 245, 244, 1)

  .cover
    width 690px
    height 310px
    border-radius 10px
    overflow hidden

    .cover-img
      width 100%
      height 100%

  .title
    width 690px
    margin-top 20px
    line-height 36px
    font-size 30px
    color rgba(51, 51, 51, 1)

</style>
